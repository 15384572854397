<template>
  <div style="width: 100%; height: 100%;">
    <div :id="id" style="width: 100%; height: 100%;"></div>
  </div>
</template>
<script>

  import highcharts from "highcharts";
  import highcharts3d from "highcharts/highcharts-3d";

  highcharts3d(highcharts);

  export default {
    props: {
      id: {
        type: String,
      },
      data: {
        type: Array,
      },
      lineCount: {
        type: String,
      },
      color: {
        type: Array,
      },
    },
    data() {
      return {
        chart: {},
      };
    },
    watch: {
      data: function() {
        this.reDraw();
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        let curveArr = [];
        for (let i = 0; i < parseInt(this.lineCount); i++) {
          let curve = {
            data: [],
            lineColor: this.color[i],
          };
          curveArr.push(curve);
        }

        this.chart = highcharts.chart(this.id, {
          chart: {
            type: "spline",//曲线
            backgroundColor: null,
          },
          credits: {//去掉水印logo
            enabled: false,
          },
          title: {
            text: "",//图表的标题文字
          },
          subtitle: {
            text: "",//副标题文字
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            series: {
              marker: {
                enabled: false,
              },
            },
          },
          yAxis: {
            title: {
              text: null,
            },
          },
          series: curveArr,
        });
      },
      reDraw() {
        let length = this.data.length;
        let data = JSON.parse(JSON.stringify(this.data));
        for (let i = 0; i < length; i++) {
          this.chart.series[i].setData(data[i].data);
        }
        this.chart.redraw();
      },
    },
  };
</script>
